import Vue from 'vue'
import Vuex from 'vuex'
import property from './property'
import category from './category'
import type from './type'

Vue.use(Vuex)

const axios = require('axios')

const state = {
    newPoi: {
        name: '',
        slogan: '',
        street: '',
        streetno: '',
        zip: '',
        city: '',
        phone: '',
        email: '',
        //website: { 'text': '', 'url': '', 'target': '_blank'},
        website: null,
        lat: '',
        lon: '',
        mainCategory: null,
        type: null,
        properties: {},
        lunchOffers: [],
        priceCategory: 3,
        priceFrom: 0,
        priceTo: 0,
        teaser: '',
        galleryImages: null,
        opening: [{ 'day': 'Montag', 'open': false, 'openingHours': null },
            { 'day': 'Dienstag', 'open': false, 'openingHours': null },
            { 'day': 'Mittwoch', 'open': false, 'openingHours': null },
            { 'day': 'Donnerstag', 'open': false, 'openingHours': null },
            { 'day': 'Freitag', 'open': false, 'openingHours': null },
            { 'day': 'Samstag', 'open': false, 'openingHours': null },
            { 'day': 'Sonntag', 'open': false, 'openingHours': null }],
        contentElements: null,
        links: null,
        imprintType: 'text',
        imprint: ''
    },
    poi: null,
    error: '',
    poiOptions: {},
    poiSelection: {}

}

const getters = {
    getPoi: () => state.poi,
    getError: () => state.error
}

const mutations = {
    setPoi: (state, { poi }) => {
        state.poi = {...poi}

        if(!state.poi.lunchOffers) {
          state.poi.lunchOffers = []
        }

        // backward-compatibilty
        if(state.poi.opening == undefined) {
            state.poi.opening = new Array()
        }
    },
    clearPoi: (state) => {
        for(let i=0; i < state.poi.opening.length; i++){
            Vue.set(state.poi.opening[i], 'openingHours', null)
            Vue.set(state.poi.opening[i], 'open', false)
        }
    },
    setError: (state, { error }) => {
        state.error = error
    },
    setPoiOptions: (state, payload) => {
        for(let i=0; i < payload.pois.length; i++){
            state.poiOptions.push( { 'value': payload.pois[i].id, 'label': payload.pois[i].name })
        }
    },
}

const actions = {
    loadPoi: function ({ commit, dispatch, rootState }, id) {
        return new Promise((resolve, reject) => {
            if (id > 0) {
                axios.get(rootState.baseUrl + 'poi/' + id, {headers: {'X-AUTH-TOKEN': rootState.authObj.token}}).then((response) => {
                    commit('setPoi', {poi: response.data})
                    dispatch('property/initPropertySelection')
                    dispatch('property/initTeaserSelection')
                    resolve(response)
                }).catch(err => {
                    if (err.response.status == 403) {
                        commit('setAuthObj', {authObj: null}, {root: true})
                    } else {
                        commit('setError', {error: 'Fehler beim Laden des POI´s'})
                        reject(err)
                    }
                })
            } else {
                commit('setPoi', {poi: state.newPoi})
                dispatch('property/initPropertySelection')
                dispatch('property/initTeaserSelection')
                resolve()
            }
            dispatch('property/loadProperties')
            dispatch('category/loadCategories')
            dispatch('type/loadTypes')
        })
    },
    savePoi: function ({ commit, getters, rootState, rootGetters }, payload) {
        // special handling of fields
        state.poi.teaser = payload.teaser
        state.poi.properties = getters['property/getPropertySelection']

        /* we must send only id´s to api no objects */
        //let poi = state.poi
        let poi = {}
        Object.assign(poi, state.poi)

        poi.mainCategory = state.poi.mainCategory.id
        poi.type = state.poi.type.id

        let properties = []
        for (let i = 0; i < state.poi.properties.length; i++){
            properties.push(state.poi.properties[i].id)
        }
        poi.properties = properties

        poi.teaserImage = state.poi.teaserImage ? state.poi.teaserImage.id : ''
        poi.logo = state.poi.logo ? state.poi.logo.id : ''
        poi.menu = state.poi.menu ? state.poi.menu.id : ''
        poi.highlightImage = state.poi.highlightImage ? state.poi.highlightImage.id : ''

        let gallery = []
        if(state.poi.galleryImages){
            for(let i = 0; i < state.poi.galleryImages.length; i++){
                if(state.poi.galleryImages[i] != null && state.poi.galleryImages[i].id){
                    gallery.push(state.poi.galleryImages[i].id)
                }
            }
        }
        poi.galleryImages = gallery

        return new Promise((resolve, reject) => {
            if(payload.id > 0){
                // update existing poi
                axios.put( rootState.baseUrl + 'poi/' + payload.id, poi, { headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }
                ).then(response => {
                    resolve(response);
                }).catch(error => {
                    if(error.response.status == 403){
                        commit('setAuthObj', { authObj: null }, { root: true })
                    } else {
                        reject(error)
                    }
                })
            } else {
                // create new poi
                axios.post( rootState.baseUrl + 'poi', poi, { headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }
                ).then((response)=>{
                    resolve(response);
                }).catch(error => {
                    if(error.response.status == 403){
                        commit('setAuthObj', { authObj: null }, { root: true })
                    } else {
                        reject(error)
                    }
                })
            }
        })
    },
    initPoiOptions: function ({ commit, state, rootState }) {
        return new Promise((resolve, reject) => {
            state.poiOptions = []

            axios.get(rootState.baseUrl + 'poi', { headers: { 'X-AUTH-TOKEN': rootState.authObj.token } } ).then((response) => {
                commit('setPoiOptions', {pois: response.data})
                resolve(response)
            }).catch(err => {
                if (err.response.status == 403) {
                    commit('setAuthObj', {authObj: null}, {root: true})
                } else {
                    commit('setError', {error: 'Fehler beim Laden'})
                }
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    modules: {
        property,
        category,
        type,
    },
    actions,
    mutations,
    getters,
}
