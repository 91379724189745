import Vue from 'vue'
import Vuex from 'vuex'
import VueNotifications from "vue-notifications";

Vue.use(Vuex)

const axios = require('axios')

const state = {
    newProperty: {
        label: '',
    },
    property: null,
    properties: null,
    error: '',
    propertyOptions: {},
    // propertySelection: {},
    // teaserSelection: []
}

const mutations = {
    setOption: (state, { type, options }) => {
        if (!state.propertyOptions[type]) {
            Vue.set(state.propertyOptions, type, [])
        }
        state.propertyOptions[type] = options
    },
    setPropertySelection: (state, payload) => {
        if (!state.propertySelection[payload.type]) {
            Vue.set(state.propertySelection, payload.type, [])
        }
        state.propertySelection[payload.type].push(payload.value)
    },
    setTeaserSelection: (state, payload) => {
        state.teaserSelection.push(payload.value)
    },
    setProperty: (state, { property }) => {
        state.property = property
    },
    setProperties: (state, {properties }) => {
        state.properties = properties
    },
    setError: (state, { error }) => {
        state.error = error;
    }
}

const getters = {
    getPropertySelection: state => {
        let selection = []
        if(state.propertySelection) {
            let types = Object.keys(state.propertySelection)
            for (let i = 0; i < Object.keys(state.propertySelection).length; i++) {
                if(state.propertySelection[types[i]]) {
                    for (let j = 0; j < state.propertySelection[types[i]].length; j++) {
                        selection.push(state.propertySelection[types[i]][j]);
                    }
                }
            }
        }
        return selection
    },
    // getTeaserSelection: () => state.teaserSelection,
    getProperty: () => state.property,
    getError: () => state.error,
}



const actions = {
    // initPropertySelection: function ({ commit, state, rootState }) {
    //     axios.get(rootState.baseUrl + 'property').then((response) => {
    //         let types = []
    //         response.data.forEach(property => {
    //             if (!types.indexOf(property.type) >= 0) {
    //                 types.push(property.type)
    //                 commit('setOption', {type: property.type, options: response.data.filter(el => el.type === property.type)})
    //             }
    //         })
    //     }).catch(err => {
    //         if(err.response.status == 403){
    //             commit('setAuthObj', { authObj: null }, { root: true })
    //         } else {
    //             commit('setError', { error: 'Fehler beim Laden'})
    //         }
    //     })
    // },
    initProperty: function ({ commit } ) {
        commit('setProperty', { property: {
                label: '',
            }
        })
    },
    loadProperty: function ({ commit, rootState }, id) {
        axios.get( rootState.baseUrl + 'property/' + id).then((response) => {
            commit('setProperty', { property: response.data })
        }).catch(err => {
            if(err.response.status == 403){
                commit('setAuthObj', { authObj: null }, { root: true })
            } else {
                commit('setError', { error: 'Fehler beim Laden des Objekts'})
            }
        })
    },
    loadProperties: function ({ commit, rootState }) {
        axios.get(rootState.baseUrl + 'property').then((response) => {
            commit('setProperties', {properties: response.data})
            let types = []
            response.data.forEach(property => {
                if (!types.indexOf(property.type) >= 0) {
                    types.push(property.type)
                    commit('setOption', {type: property.type, options: response.data.filter(el => el.type === property.type)})
                }
            })
        }).catch(err => {
            if(err.response.status == 403){
                commit('setAuthObj', { authObj: null }, { root: true })
            } else {
                commit('setError', { error: 'Fehler beim Laden'})
            }
        })
    },
    // initTeaserSelection: function ({ commit, state, rootState }) {
    //     // clear array
    //     state.teaserSelection = []
    //
    //     if(rootState.poi.poi.properties.length){
    //         // map by label because teaser ist string field
    //         let teaser = rootState.poi.poi.teaser.replace(/, /g, ',').split(',');
    //         for(let i = 0; i < teaser.length; i++){
    //             rootState.poi.poi.properties.forEach(property => {
    //                 if(property.label == teaser[i]) {
    //                     commit('setTeaserSelection', {value: property})
    //                 }
    //             })
    //         }
    //     }
    // },
    // updateTeaserSelection: function ({ commit, state, rootState }, payload){
    //     // clear array
    //     state.teaserSelection = []
    //     payload.forEach(item => {
    //         commit('setTeaserSelection', {value: item})
    //     })
    // },
    saveProperty: function ({ commit, rootState }, payload ) {
        return new Promise((resolve, reject) => {
            const data = {
                type: payload.type,
                label: payload.label,
                image: payload.image
            }

            if(payload.id > 0){
                // Update existing Property
                axios.put( rootState.baseUrl + 'property/' + payload.id, data, { headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }
                ).then(response => {
                    resolve(response);
                }).catch(error => {
                    if(error.response.status == 403){
                        commit('setAuthObj', { authObj: null }, { root: true })
                    } else {
                        reject(error)
                    }
                })
            } else {
                // Create new Property
                axios.post( rootState.baseUrl + 'property', data, { headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }
                ).then((response)=>{
                    resolve(response);
                }).catch(error => {
                    if(error.response.data.status == 403){
                        commit('setAuthObj', { authObj: null }, { root: true })
                    } else {
                        reject(error.response.data.message)
                    }
                })
            }
        })
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
