import Vue from 'vue'
import Router from 'vue-router'

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')
const LoggedOutContainer = () => import('@/containers/LoggedOutContainer')

// Views
const Login = () => import('@/views/Login')
const Media = () => import('@/views/Media/Media')
const Categories = () => import('@/views/Properties/Categories')
const EditCategory = () => import('@/views/Properties/EditCategory')
const Properties = () => import('@/views/Properties/Properties')
const EditProperty = () => import('@/views/Properties/EditProperty')
const Users = () => import('@/views/User/Users')
const EditUser = () => import('@/views/User/EditUser')
const BlogCategories = () => import('@/views/Blog/BlogCategories')
const BlogCategory = () => import('@/views/Blog/EditBlogCategory')
const BlogEntries = () => import('@/views/Blog/BlogEntries')
const Blog = () => import('@/views/Blog/Blog')
const Persons = () => import('@/views/Person/Persons')
const EditPerson = () => import('@/views/Person/EditPerson')
const Companies = () => import('@/views/Company/Companies')
const EditCompany = () => import('@/views/Company/EditCompany')
const ListOffers = () => import('@/views/Company/ListOffers')
const EditOffer = () => import('@/views/Company/EditOffer')


Vue.use(Router)

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/login',
      component: LoggedOutContainer,
      children: [
        {
          path: 'login',
          name: 'Login',
          component: Login
        }
      ]
    },
    {
      path: '/media',
      redirect: '/media/mediamanager',
      name: 'Medien',
      component: DefaultContainer,
      children: [
        {
          path: 'mediamanager',
          name: 'Medienverwaltung',
          component: Media
        }
      ]
    },
    {
      path: '/property',
      name: 'Eigenschaften',
      component: DefaultContainer,
      children: [
        {
          path: 'categories',
          name: 'Kategorien',
          component: Categories
        },
        {
          path: 'editCategory/:id',
          name: 'Gaststättenart bearbeiten',
          component: EditCategory,
        },
        {
          path: 'properties/:type',
          //name: 'Gerichte',
          component: Properties
        },
        {
          path: 'editProperty/:type/:id',
          //name: 'Gaststättenart bearbeiten',
          component: EditProperty,
        },
      ]
    },
    {
      path: '/blog',
      name: 'Blog',
      component: DefaultContainer,
      children: [
        {
          path: 'persons',
          name: 'Personen',
          component: Persons
        },
        {
          path: 'editPerson/:id',
          name: 'Person bearbeiten',
          component: EditPerson
        },
        {
          path: 'blogCategories',
          name: 'Blogkategorien',
          component: BlogCategories
        },
        {
          path: 'editBlogCategory/:id',
          name: 'Blogkategorie bearbeiten',
          component: BlogCategory
        },
        {
          path: 'blogEntries',
          name: 'Blog ',
          component: BlogEntries
        },
        {
          path: 'editBlog/:id',
          name: 'Blog bearbeiten',
          component: Blog
        }
      ]
    },
    {
      path: '/user',
      redirect: '/user/users',
      name: 'Benutzer',
      component: DefaultContainer,
      children: [
        {
          path: 'users',
          name: 'Benutzer ',
          component: Users
        },
        {
          path: 'editUser/:id',
          name: 'Benutzer bearbeiten',
          component: EditUser
        }
      ]
    },
    {
      path: '/company',
      redirect: '/company/companies',
      name: 'Unternehmen',
      component: DefaultContainer,
      children: [
        {
          path: 'companies',
          name: 'Unternehmen List',
          component: Companies
        },
        {
          path: 'editCompany/:id',
          name: 'Unternehmen bearbeiten',
          component: EditCompany
        },
        {
          path: 'offers/:id',
          name: 'Stellenangebote',
          component: ListOffers
        },
        {
          path: 'editOffer/:id',
          name: 'Stellenangebot bearbeiten',
          component: EditOffer
        },
      ]
    }
  ]
})

export default router
