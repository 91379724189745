import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const axios = require('axios')

const state = {
    typeOptions: [],
}

const mutations = {
    setTypes: (state, { types }) => {
        state.typeOptions = []
        types.forEach(type => {
            state.typeOptions.push({ value:  type, text: type.label })
        })
    },
    setError: (state, { error }) => {
        state.error = error;
    }
}

const getters = {

}

const actions = {
    loadTypes: function ({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            axios.get(rootState.baseUrl + 'type').then((response) => {
                commit('setTypes', {types: response.data})
                resolve()
            }).catch(err => {
                if(err.response != undefined && err.response.status == 403){
                    commit('setAuthObj', { authObj: null }, { root: true })
                } else {
                    commit('setError', { error: 'Fehler beim Laden der Typen'})
                }
            })
        })
    },

}



export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}