<template>
  <router-view></router-view>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
    name: 'app',
    computed: {
      ...mapGetters({
          authObj: 'getAuthObj',
      })
  },
  watch: {
      authObj: function(){
          if(this.authObj == undefined || this.authObj == null ){
              this.$router.replace(this.$route.query.redirect || '/login')
          }
      }
  },
  created() {
      if(this.authObj == null || this.authObj.token == ''){
          this.$router.replace(this.$route.query.redirect || '/login')
      }
  },
  updated() {
      if(this.authObj == null || this.authObj.token == ''){
          this.$router.replace(this.$route.query.redirect || '/login')
      }
  }
}
</script>

<style lang="scss">
  // CoreUI Icons Set
  @import '~@coreui/icons/css/coreui-icons.min.css';
  /* Import Font Awesome Icons Set */
  $fa-font-path: '~font-awesome/fonts/';
  @import '~font-awesome/scss/font-awesome.scss';
  /* Import Simple Line Icons Set */
  $simple-line-font-path: '~simple-line-icons/fonts/';
  @import '~simple-line-icons/scss/simple-line-icons.scss';
  /* Import Flag Icons Set */
  @import '~flag-icon-css/css/flag-icon.min.css';
  /* Import Bootstrap Vue Styles */
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
