import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const axios = require('axios')

 const state = {
     person: null,
     persons: [],
     authorOptions: [],
     error: '',
}

 const mutations = {
     setPerson: (state, { person }) => {
         state.person = person
     },
     setPersons: (state, { persons }) => {
         state.persons = persons
         state.authorOptions = []
         persons.forEach(person => {
             state.authorOptions.push({ value:  person.id, text: person.firstname + person.lastname })
         })
     },
     setError: (state, { error }) => {
         state.error = error;
     }
 }

 const actions = {
     initPerson: function ({ commit } ) {
         commit('setPerson', { person: {
                 id: '-1',
                 firstname: '',
                 lastname: '',
                 jobTitle: '',
                 description: '',
                 image: { undefined },
                 links: []
             }
         })
     },
     loadPerson: function ({ commit, dispatch, rootState }, id) {
         axios.get( rootState.baseUrl + 'person/' + id, {headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }).then((response) => {
             commit('setPerson', { person: response.data })
         }).catch(err => {
             if(err.response.status == 403){
                 commit('setAuthObj', { authObj: null }, { root: true })
             } else {
                 commit('setError', { error: 'Fehler beim Laden der Person'})
             }
         })
     },
     loadAllPersons: function({ commit, rootState } ){
         axios.get(rootState.baseUrl + 'person', {headers: {'X-AUTH-TOKEN': rootState.authObj.token } }).then((response) => {
            commit('setPersons', { persons: response.data })
         }).catch(err => {
             if (err.response.status == 403) {
                 commit('setAuthObj', { authObj: null }, { root: true })
             } else {
                 commit('setError', { error: 'Fehler beim Laden der Personen'})
             }
         })
     },
     savePerson: function ({ commit, getters, rootState } ) {
         let data = (Object.assign({}, getters.getPerson))
         data.image = data.image ? data.image.id : ''

         return new Promise((resolve, reject) => {
             if(state.person.id == -1){
                 // create new person
                 axios.post( rootState.baseUrl + 'person', data, { headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }
                 ).then((response)=>{
                     commit('setPerson', { person: response.data })
                     resolve(response);
                 }).catch(error => {
                     if(error.response.status == 403){
                         commit('setAuthObj', { authObj: null }, { root: true })
                     } else {
                         reject(error)
                     }
                 })
             } else {
                 // update existing person
                 axios.put( rootState.baseUrl + 'person/' + data.id, data, { headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }
                 ).then(response => {
                     resolve(response);
                 }).catch(error => {
                     if(error.response.status == 403){
                         commit('setAuthObj', { authObj: null }, { root: true })
                     } else {
                         reject(error)
                     }
                 })
             }
         })
     },
     deletePerson: function({commit, dispatch, rootState}, id) {
         return new Promise((resolve, reject) => {
            axios.delete(rootState.baseUrl + 'person/' + id, { headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }
            ).then((response)=> {
                dispatch('loadAllPersons')
            }).catch(error => {
                if(error.response.status == 403){
                    commit('setAuthObj', { authObj: null }, { root: true })
                } else {
                    reject(error)
                }
             })
         })
     }
 }

 const getters = {
     getPerson: () => state.person,
     getPersons: () => state.persons,
     getError: () => state.error
 }

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
