import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const axios = require('axios')

const state = {
    media: null,
    folders: null,
    selected: null,
    folderOptions: {},
    error: '',

}

const mutations = {
    setMedia: (state, { media }) => {
        state.media = media
    },
    setFolders: (state, { folders }) => {
        state.folders = folders
    },
    setSelected: (state, { media }) => {
        state.selected = media
    },
    setError: (state, { error }) => {
        state.error = error;
    },
    addFolderOption: (state, payload) => {
        state.folderOptions.push( { 'value': payload.folder.id, 'label': payload.folder.name })
    },
}

const actions = {
    loadMedia: function ({ commit, rootState }, payload) {
        axios.get( rootState.baseUrl + 'media/' + payload.folderId, {headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }).then((response) => {
            commit('setMedia', { media: response.data })
        }).catch(err => {
            if(err.response.status == 403){
                commit('setAuthObj', { authObj: null }, { root: true })
            } else {
                reject(err)
            }
            commit('setError', { err: 'Fehler beim Laden der Medien'})
        })
    },
    loadFolders: function({commit, rootState }, payload){
        axios.get( rootState.baseUrl + 'media-folder/' + payload.level, {headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }).then((response) => {
            commit('setFolders', { folders: response.data })
        }).catch(err => {
            if(err.response.status == 403){
                commit('setAuthObj', { authObj: null }, { root: true })
            } else {
                reject(err)
            }
            commit('setError', { err: 'Fehler beim Laden der Ordner'})
        })
    },
    setSelectedMedia: function({ commit }, media) {
        commit('setSelected', { media: media.media })
    },
    saveFolder: function({ commit, rootState }, payload ) {
        return new Promise((resolve, reject) => {
            axios.post(rootState.baseUrl + 'media-folder', payload, {headers: {'X-AUTH-TOKEN': rootState.authObj.token}}).then((response) => {
                resolve(response)
            }).catch(error => {
                if (error.response.status == 403) {
                    commit('setAuthObj', {authObj: null}, {root: true})
                } else {
                    // TODO: code me!
                }
            })
        })
        this.uploadModal = false
    },
    updateFolder: function({ commit, rootState }, payload ) {
        return new Promise((resolve, reject) => {
            axios.put(rootState.baseUrl + 'media-folder/' + payload.id, payload.data, {headers: {'X-AUTH-TOKEN': rootState.authObj.token}}).then((response) => {
                resolve(response)
            }).catch(error => {
                if (error.response.status == 403) {
                    commit('setAuthObj', {authObj: null}, {root: true})
                } else {
                    // TODO: code me!
                }
            })
        })
        this.uploadModal = false
    },
    initFolderOptions: function ({ commit, dispatch, state, rootState }) {
        return new Promise((resolve, reject) => {
            state.folderOptions = []

            axios.get(rootState.baseUrl + 'media-folder', {headers: {'X-AUTH-TOKEN': rootState.authObj.token}}).then((response) => {
                dispatch('flattenFolderAndPushToOptions', {folders: response.data})
                resolve(response)
            }).catch(err => {
                commit('setError', {error: 'Fehler beim Laden'})
            })
        })
    },

    flattenFolderAndPushToOptions: function ( {commit, dispatch}, payload ) {
        for(let i=0; i < payload.folders.length; i++){
            // recursive call for folder-children
            commit('addFolderOption', {folder: payload.folders[i]})
            if(payload.folders[i].children.length > 0){
                dispatch('flattenFolderAndPushToOptions', {folders: payload.folders[i].children})
            }
        }
    }
}

const getters = {
    /*getMedia: ( state ) => {
        if(!state.media){
            this.loadMedia
        }
        return state.media
    },*/

    getMedia: () => state.media,
    getFolders: () => state.folders,
    getSelected: () => state.selected,
    getError: () => state.error
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}