import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const axios = require('axios')

const state = {
    newCategory: {
        name: '',
    },
    category: null,
    categories: null,
    categoryOptions: [],
    error: '',

}

const mutations = {
    setOption: (state, { categories }) => {
        state.categoryOptions = []
        categories.forEach(category => {
            state.categoryOptions.push({ value:  category, text: category.name })
        })

    },

    setCategory: (state, { category }) => {
        state.category = category
    },
    setCategories: (state, {categories }) => {
        state.categories = categories
    },
    setError: (state, { error }) => {
        state.error = error;
    }
}

const getters = {
    getCategory: () => state.category,
    getCategories: () => state.categories,
    getError: () => state.error
}

const actions = {
    initCategory: function ({ commit } ) {
        commit('setCategory', { category: {
                name: '',
            }
        })
    },
    loadCategory: function ({ commit, rootState }, id) {
        axios.get( rootState.baseUrl + 'category/' + id).then((response) => {
            commit('setCategory', { category: response.data })
        }).catch(err => {
            if(err.response.status == 403){
                commit('setAuthObj', { authObj: null }, { root: true })
            } else {
                commit('setError', { error: 'Fehler beim Laden der Kategorie'})
            }
        })
    },
    loadCategories: function ({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            axios.get(rootState.baseUrl + 'category').then((response) => {
                commit('setCategories', {categories: response.data})
                commit('setOption', {categories: response.data})
                resolve()
            }).catch(err => {
                if(err.response.status == 403){
                    commit('setAuthObj', { authObj: null }, { root: true })
                } else {
                    commit('setError', { error: 'Fehler beim Laden der Kategorien'})
                }
            })
        })
    },
    saveCategory: function ({ commit, rootState }, payload ) {
        return new Promise((resolve, reject) => {
            const data = {
                name: payload.name,
                image: payload.image,
                logoImage: payload.logoImage,
            }

            if(payload.id > 0){
                // Update existing Category
                axios.put( rootState.baseUrl + 'category/' + payload.id, data, { headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }
                ).then(response => {
                    resolve(response);
                }).catch(error => {
                    if(error.response.status == 403){
                        commit('setAuthObj', { authObj: null }, { root: true })
                    } else {
                        reject(error)
                    }
                })
            } else {
                // Create new Category
                axios.post( rootState.baseUrl + 'category', data, { headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }
                ).then((response)=>{
                    resolve(response);
                }).catch(error => {
                    if(error.response.status == 403){
                        commit('setAuthObj', { authObj: null }, { root: true })
                    } else {
                        reject(error.response.data.message)
                    }
                })
            }
        })
    }
}



export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
