import Vue from 'vue'
import Vuex from 'vuex'
import person from './person'
import blogCategory from "./blogCategory";



Vue.use(Vuex)

const axios = require('axios')

const state = {
    blog: null,
    blogEntries:[],
    error: '',
}

const mutations = {
    setBlog: (state, { blog }) => {
        state.blog = blog
    },
    setBlogEntries: (state, { blogEntries }) => {
        state.blogEntries = blogEntries
    },
    setError: (state, { error }) => {
        state.error = error;
    }
}

const actions = {
    initBlog: function ({ commit, dispatch } ) {
        commit('setBlog', { blog: {
                id: '-1',
                title: '',
                date: '',
                readingTime: 0,
                author: -1,
                blogCategory: -1,
                subcategories: [],
                blogElements: [],
                heroImage: ''
            }
        })
        dispatch('person/loadAllPersons')
        // dispatch('region/loadAllRegions')
        dispatch('blogCategory/loadBlogCategories')
    },
    loadBlog: function ({ commit, dispatch, rootState }, id) {
        dispatch('initBlog')
        axios.get( rootState.baseUrl + 'blog/' + id, {headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }).then((response) => {
            //commit('setBlog', { blog: response.data })

            // special handling to load blogElements from json-string
            let blogElements = []
            for(let i = 0; i < response.data.blogElements.length; i++){
                blogElements.push(JSON.parse(response.data.blogElements[i].content))
            }

            let subcategories = []
            response.data.subcategories.forEach(e => {
              subcategories.push({'value': e.id, 'text': e.name})
            });

            commit('setBlog', { blog: {
                    id: response.data.id,
                    title: response.data.title,
                    slug: response.data.slug,
                    date: response.data.date,
                    readingTime: response.data.readingTime,
                    author: response.data.author ? response.data.author.id : '',
                    blogCategory: response.data.blogCategory ? response.data.blogCategory.id : '',
                    blogElements: blogElements,
                    heroImage: response.data.heroImage,
                    teaserImage: response.data.teaserImage,
                    subcategories: subcategories,
                    video_youtube_id: response.data.video_youtube_id,
                    seoTitle: response.data.seoTitle,
                    seoDescription: response.data.seoDescription
                }
            })

        }).catch(err => {
            if(err.response && err.response.status == 403){
                commit('setAuthObj', { authObj: null }, { root: true })
            } else {
                commit('setError', { error: 'Fehler beim Laden des Blogbeitrags'})
            }
        })
        // dispatch('person/loadAllPersons')
    },
    loadAllBlogEntries: function({ commit, rootState } ){
        axios.get(rootState.baseUrl + 'blog', {headers: {'X-AUTH-TOKEN': rootState.authObj.token } }).then((response) => {
            commit('setBlogEntries', { blogEntries: response.data })
        }).catch(err => {
            if (err.response && err.response.status == 403) {
                commit('setAuthObj', { authObj: null }, { root: true })
            } else {
                commit('setError', { error: 'Fehler beim Laden der Blogeinträge'})
            }
        })
    },

    saveBlog: function ({ commit, getters, rootState } ) {
        return new Promise((resolve, reject) => {

            // special handling for blog-elements to store json in API-db in field content
            let blog = {}
            Object.assign(blog, state.blog)
            blog.blogElements = []
            for(let i = 0; i < state.blog.blogElements.length; i++){
                blog.blogElements.push({ 'content' : JSON.stringify(state.blog.blogElements[i]) } )
            }

            // special handling for images, send only id
            blog.heroImage = state.blog.heroImage ? state.blog.heroImage.id : ''
            blog.teaserImage = state.blog.teaserImage ? state.blog.teaserImage.id : ''

          let subcategories = []
          for (let i = 0; i < blog.subcategories.length; i++){
            subcategories.push(blog.subcategories[i].value)
          }
          blog.subcategories = subcategories

            if(state.blog.id == -1){
                // create new blog entry
                axios.post( rootState.baseUrl + 'blog', blog, { headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }
                ).then((response)=>{
                    commit('setBlog', { blog: response.data })
                    resolve(response);
                }).catch(error => {
                    if(error.response.status == 403){
                        commit('setAuthObj', { authObj: null }, { root: true })
                    } else {
                        reject(error)
                    }
                })
            } else {
                // update existing blog entry
                axios.put( rootState.baseUrl + 'blog/' + getters.getBlog.id, blog, { headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }
                ).then(response => {
                    resolve(response);
                }).catch(error => {
                    if(error.response.status == 403){
                        commit('setAuthObj', { authObj: null }, { root: true })
                    } else {
                        reject(error)
                    }
                })
            }
        })
    },
    deleteBlog: function({commit, dispatch, rootState}, id) {
        return new Promise((resolve, reject) => {
            axios.delete(rootState.baseUrl + 'blog/' + id, { headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }
            ).then((response)=> {
                dispatch('loadAllBlogEntries')
            }).catch(error => {
                if(error.response.status == 403){
                    commit('setAuthObj', { authObj: null }, { root: true })
                } else {
                    reject(error)
                }
            })
        })
    }
}

const getters = {
    getBlog: () => state.blog,
    getBlogEntries: () => state.blogEntries,
    getError: () => state.error
}

export default {
    namespaced: true,
    state,
    modules: {
        person,
        blogCategory,
    },
    actions,
    mutations,
    getters,
}
