import Vue from 'vue'
import Vuex from 'vuex'

import property from './poi/property'

Vue.use(Vuex)

const axios = require('axios')

const state = {
    company: {
        stellenangebotes: []
    },
    companyEntries:[],
    error: '',
    offer: {
        content: [],
        benefits: []
    }
}

const mutations = {
    setCompany: (state, { company }) => {
        state.company = company
    },
    setOffer: (state, { offer }) => {
        state.offer = offer
    },
    setCompanyEntries: (state, { companyEntries }) => {
        state.companyEntries = companyEntries
    },
    setError: (state, { error }) => {
        state.error = error;
    }
}

const actions = {
    initCompany: function ({ commit, dispatch } ) {
        commit('setCompany', { company: {
                id: '-1',
                title: '',
                slug: '',
                lat: '',
                lon: '',
                text: '',
                image: {},
                video: {},
                image_bg: {},
                imageWhiteLogo: {},
                stellenangebotes: [],
                standorts: [],
                socialMedia: [],
                enabled: true,
                branches: []
            }
        })
    },
    initOffer: function ({ commit, dispatch } ) {
        commit('setOffer', { offer: {
                id: '-1',
                content: [],
                locations: [],
                departments: [],
                benefits: []
            }
        })
    },
    loadCompany: function ({ commit, dispatch, rootState }, id) {
        dispatch('initCompany')
        return axios.get( rootState.baseUrl + 'company/' + id, {headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }).then((response) => {
            let data = response.data

            // handle: many to many relation
            let branches = []
            data.branches.forEach(e => {
                branches.push(e.id)
            })
            data.branches = branches

            commit('setCompany', { company: data })
        }).catch(err => {
            if(err.response && err.response.status == 403){
                commit('setAuthObj', { authObj: null }, { root: true })
            } else {
                commit('setError', { error: 'Fehler beim Laden des Unternehmens'})
            }
        })
    },
    loadAllCompanyEntries: function({ commit, rootState } ){
        axios.get(rootState.baseUrl + 'company', {headers: {'X-AUTH-TOKEN': rootState.authObj.token } }).then((response) => {
            commit('setCompanyEntries', { companyEntries: response.data })
        }).catch(err => {
            if (err.response && err.response.status == 403) {
                commit('setAuthObj', { authObj: null }, { root: true })
            } else {
                commit('setError', { error: 'Fehler beim Laden der SEO-Regionen'})
            }
        })
    },

    saveCompany: function ({ commit, getters, rootState } ) {
        return new Promise((resolve, reject) => {

            // special handling for blog-elements to store json in API-db in field content
            const company = {}
            Object.assign(company, getters.getCompany)

            // special handling for images, send only id
            company.image = state.company.image ? state.company.image.id : ''
            company.imageWhiteLogo = state.company.imageWhiteLogo ? state.company.imageWhiteLogo.id : ''
            company.imageLogo = state.company.imageLogo ? state.company.imageLogo.id : ''

            company.stellenangebotes = []
            company.socialMedia = JSON.stringify(company.socialMedia)

            if(state.company.id == -1){
                // create new company
                axios.post( rootState.baseUrl + 'company', company, { headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }
                ).then((response)=>{
                    resolve(response);
                }).catch(error => {
                    if(error.response.status == 403){
                        commit('setAuthObj', { authObj: null }, { root: true })
                    } else {
                        reject(error)
                    }
                })
            } else {
                // update existing company
                axios.put( rootState.baseUrl + 'company/' + company.id, company, { headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }
                ).then(response => {
                    resolve(response);
                }).catch(error => {
                    if(error.response.status == 403){
                        commit('setAuthObj', { authObj: null }, { root: true })
                    } else {
                        reject(error)
                    }
                })
            }
        })
    },
    deleteCompany: function({commit, dispatch, rootState}, id) {
        return new Promise((resolve, reject) => {
            axios.delete(rootState.baseUrl + 'company/' + id, { headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }
            ).then((response)=> {
                dispatch('loadAllCompanyEntries')
            }).catch(error => {
                if(error.response.status == 403){
                    commit('setAuthObj', { authObj: null }, { root: true })
                } else {
                    reject(error)
                }
            })
        })
    },
    deleteOffer: function({commit, dispatch, rootState}, id) {
        return new Promise((resolve, reject) => {
            axios.delete(rootState.baseUrl + 'offer/' + id, { headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }
            ).then((response)=> {
                resolve(response)
            }).catch(error => {
                if(error.response.status == 403){
                    commit('setAuthObj', { authObj: null }, { root: true })
                } else {
                    reject(error)
                }
            })
        })
    },


    loadOffer: function ({ commit, dispatch, rootState }, id) {
        dispatch('initOffer')
        if(id == -1) {
            return
        }
        return axios.get( rootState.baseUrl + 'offer/' + id, {headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }).then((response) => {
            let data = response.data
            // special handling to load the stellen and standort within the offer
            data.companyLocation = data.companyLocation? data.companyLocation.id : ''
            data.branch = data.branch? data.branch.id : ''
            data.minGraduation = data.minGraduation? data.minGraduation.id : ''
            data.beginningPeriod = data.beginningPeriod? data.beginningPeriod.id : ''

            let locations = []
            response.data.locations.forEach(e => {
                locations.push({'value': e.id, 'text': e.name})
            });
            data.locations = locations

            let departments = []
            data.departments.forEach(e => {
                departments.push(e.id)
            })
            data.departments = departments

            // handle JSON data
            data.content = JSON.parse(data.content)
            // data.benefits = JSON.parse(data.benefits)

            commit('setOffer', { offer: data })
        }).catch(err => {
            if(err.response && err.response.status == 403){
                commit('setAuthObj', { authObj: null }, { root: true })
            } else {
                commit('setError', { error: 'Fehler beim Laden des Unternehmens'})
            }
        })
    },
    saveOffer: function ({ commit, getters, rootState }) {
        return new Promise((resolve, reject) => {

            // special handling for blog-elements to store json in API-db in field content
            const offer = {}
            Object.assign(offer, getters.getOffer)

            offer.image = offer.image? offer.image.id : ''
            offer.content = JSON.stringify(offer.content)
            offer.benefits = JSON.stringify(offer.benefits)

            offer.firma = state.company.id

            let locations = []
            for (let i = 0; i < offer.locations.length; i++){
                locations.push(offer.locations[i].value)
            }
            offer.locations = locations

            if(state.offer.id == -1){
                // create new company
                axios.post( rootState.baseUrl + 'offer', offer, { headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }
                ).then((response)=>{
                    resolve(response);
                }).catch(error => {
                    if(error.response.status == 403){
                        commit('setAuthObj', { authObj: null }, { root: true })
                    } else {
                        reject(error)
                    }
                })
            } else {
                // update existing company
                axios.put( rootState.baseUrl + 'offer/' + offer.id, offer, { headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }
                ).then(response => {
                    resolve(response);
                }).catch(error => {
                    reject(error)
                    if(error.response.status == 403){
                        commit('setAuthObj', { authObj: null }, { root: true })
                    } else {
                    }
                })
            }
        })
    },
}

const getters = {
    getCompany: () => state.company,
    getOffer: () => state.offer,
    getCompanyEntries: () => state.companyEntries,
    getOfferEntries: () => state.company.stellenangebotes,
    getError: () => state.error
}

export default {
    namespaced: true,
    state,
    modules: {
        property
    },
    actions,
    mutations,
    getters,
}
