import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const axios = require('axios')

const state = {
    newBlogCategory: {
        name: '',
    },
    blogCategory: null,
    blogCategories: null,
    blogCategoryOptions: [],
    error: '',

}

const mutations = {
    setOption: (state, { blogCategories }) => {
        state.blogCategoryOptions = []
        blogCategories.forEach(blogCategory => {
            state.blogCategoryOptions.push({ value:  blogCategory.id, text: blogCategory.name })
        })

    },

    setBlogCategory: (state, { blogCategory }) => {
        state.blogCategory = blogCategory
    },
    setBlogCategories: (state, {blogCategories }) => {
        state.blogCategories = blogCategories
    },
    setError: (state, { error }) => {
        state.error = error;
    }
}

const getters = {
    getBlogCategory: () => state.blogCategory,
    getBlogCategories: () => state.blogCategories,
    getError: () => state.error
}

const actions = {
    initBlogCategory: function ({ commit } ) {
        commit('setBlogCategory', { blogCategory: {
                name: '',
                slug: ''
            }
        })
    },
    loadBlogCategory: function ({ commit, rootState }, id) {
        axios.get( rootState.baseUrl + 'blog-category/' + id).then((response) => {
            commit('setBlogCategory', { blogCategory: response.data })
        }).catch(err => {
            if(err.response.status == 403){
                commit('setAuthObj', { authObj: null }, { root: true })
            } else {
                commit('setError', { error: 'Fehler beim Laden der Blogkategorie'})
            }
        })
    },
    loadBlogCategories: function ({ commit, rootState }) {
        return new Promise((resolve, reject) => {
            axios.get(rootState.baseUrl + 'blog-category').then((response) => {
                commit('setBlogCategories', {blogCategories: response.data})
                commit('setOption', {blogCategories: response.data})
                resolve()
            }).catch(err => {
                if(err.response.status == 403){
                    commit('setAuthObj', { authObj: null }, { root: true })
                } else {
                    commit('setError', { error: 'Fehler beim Laden der Blogkategorien'})
                }
            })
        })
    },
    saveBlogCategory: function ({ commit, rootState }, payload ) {
        return new Promise((resolve, reject) => {
            const data = {
                name: payload.name,
                slug: payload.slug,
            }

            if(payload.id > 0){
                // Update existing Category
                axios.put( rootState.baseUrl + 'blog-category/' + payload.id, data, { headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }
                ).then(response => {
                    resolve(response);
                }).catch(error => {
                    if(error.response.status == 403){
                        commit('setAuthObj', { authObj: null }, { root: true })
                    } else {
                        reject(error)
                    }
                })
            } else {
                // Create new Category
                axios.post( rootState.baseUrl + 'blog-category', data, { headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }
                ).then((response)=>{
                    resolve(response);
                }).catch(error => {
                    if(error.response.status == 403){
                        commit('setAuthObj', { authObj: null }, { root: true })
                    } else {
                        reject(error)
                    }
                })
            }
        })
    }
}



export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
