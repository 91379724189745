import Vue from 'vue'
import Vuex from 'vuex'
import poi from './modules/poi/index'
import media from './modules/media'
import user from './modules/user'
import person from './modules/person'
import blog from './modules/blog'
import blogCategory from "./modules/blogCategory";
import company from './modules/company'

import category from './modules/poi/category'
//import property from './modules/poi/property'

Vue.use(Vuex)

const axios = require('axios')

const state = {
    baseUrl: process.env.VUE_APP_API_BASEURL,
    mediaServiceUrl: process.env.VUE_APP_MEDIA_BASEURL,
    videoServiceUrl: process.env.VUE_APP_VIDEO_BASEURL,
    error: '',
    authObj: {
        token: '',
        username: '',
        firstname: '',
        lastname: '',
        role: '',
        rolename: ''
    }
}

const mutations = {
    setAuthObj: (state, { authObj }) => {
        if(authObj != null){
            sessionStorage.loggedInUser = JSON.stringify(authObj)
        } else {
            sessionStorage.loggedInUser = null
        }
        state.authObj = authObj
    },
    setError: (state, { error }) => {
        state.error = error;
    }
}

const actions = {
    doLogin: function ({ commit, rootState }, payload) {
        axios.post( rootState.baseUrl + 'user/login', {
            username: payload.username, //state.username,
            password: payload.password //state.password
        }).then((response) => {
            commit('setAuthObj', { authObj: response.data })
            commit('setError', { error: ''})
        }, (err) => {
            commit('setError', { error: 'Login fehlgeschlagen'})
        })
    },
    doLogout: function ({ commit, rootState }, username) {
        axios.delete(rootState.baseUrl + 'user/logout/' + username, {headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }).then(
            commit('setAuthObj', { authObj:  null }))
    },
}

const getters = {
    getAuthObj: ( state ) => {
        let userObj = sessionStorage.loggedInUser
        if(userObj != undefined && userObj != null){
            state.authObj = JSON.parse(sessionStorage.loggedInUser)
        } else {
            state.authObj = { 'token': '', 'username': '', 'firstname': '', 'lastname': '' }
        }
        return state.authObj
    },

    getError: () => state.error
}

const store = new Vuex.Store({
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
    modules: {
        poi,
        media,
        user,
        person,
        blog,
        blogCategory,
        company
    }
})

export default store