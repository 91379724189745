import Vue from 'vue'
import Vuex from 'vuex'
import poi from './poi/index'
import media from './media'

Vue.use(Vuex)

const axios = require('axios')

 const state = {
     newUser: {
         username: '',
         password: '',
         firstname: '',
         lastname: '',
// TODO: role für Admin-Flag
     },
     user: null,
     error: '',
}

 const mutations = {
     setUser: (state, { user }) => {
         state.user = user
     },
     setError: (state, { error }) => {
         state.error = error;
     }
 }

 const actions = {
     initUser: function ({ commit } ) {
         commit('setUser', { user: {
                 username: '',
                 password: '',
                 firstname: '',
                 lastname: '',
                 pois: [],
                 mediaFolders: []
             }
         })
     },
     loadUser: function ({ commit, dispatch, rootState }, id) {
         axios.get( rootState.baseUrl + 'user/' + id, {headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }).then((response) => {
             commit('setUser', { user: response.data })
         }).catch(err => {
             if(err.response.status == 403){
                 commit('setAuthObj', { authObj: null }, { root: true })
             } else {
                 commit('setError', { error: 'Fehler beim Laden des Benutzers'})
             }
         })
     },
     saveUser: function ({ commit, rootState }, payload ) {
         return new Promise((resolve, reject) => {
             if(payload.id == -1){
                 // create new user
                 axios.post( rootState.baseUrl + 'user', state.user, { headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }
                 ).then((response)=>{
                     resolve(response);
                 }).catch(error => {
                     if(error.response.status == 403){
                         commit('setAuthObj', { authObj: null }, { root: true })
                     } else {
                         reject(error)
                     }
                 })
             } else {
                 // update existing user
                 axios.put( rootState.baseUrl + 'user/' + state.user.username, state.user, { headers: { 'X-AUTH-TOKEN': rootState.authObj.token } }
                 ).then(response => {
                     resolve(response);
                 }).catch(error => {
                     if(error.response.status == 403){
                         commit('setAuthObj', { authObj: null }, { root: true })
                     } else {
                         reject(error)
                     }
                 })
             }
         })
     }
 }

 const getters = {
     getUser: () => state.user,
     getError: () => state.error
 }

export default {
    namespaced: true,
    state,
    modules: {
        poi,
        media,
    },
    actions,
    mutations,
    getters,
}
